<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button (click)="toggleSideBar()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>

    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
        <li>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </li>
        <li>
          <button mat-icon-button>
            <mat-icon>help_outline</mat-icon>
          </button>
        </li>
        <li>
          <button [matMenuTriggerFor]="menu" mat-button>
            <mat-icon>person_outline</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-icon>exit_to_app</mat-icon>
              Sign out
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
