
<h1 mat-dialog-title>Dialog: {{ action }} </h1>

<mat-divider></mat-divider>

<app-query-builder></app-query-builder>

<mat-divider></mat-divider>

<!-- FOOTER -->
<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1%" style="padding-top: 2%">
  <button color="warn" mat-raised-button>
    Reset
  </button>
  <button color="primary" mat-raised-button>
    <mat-icon>save_alt</mat-icon>
    Save
  </button>
</div>



