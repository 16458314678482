import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultComponent} from './layouts/default/default.component';
import {AnalyzerComponent} from './modules/analyzer/analyzer.component';
import {FilterComponent} from './modules/filter/filter.component';
import {SwaggerComponent} from './modules/swagger/swagger.component';

const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{
    path: '',
    component: AnalyzerComponent
  }, {
    path: 'filter',
    component: FilterComponent
  }, {
    path: 'swagger',
    component: SwaggerComponent
  }]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
