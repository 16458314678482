import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {TableDataSource, TableItem} from './table-datasource';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {QueryComponent} from '../query/query.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TableItem>;
  dataSource: TableDataSource;

  searchKey: string;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['toggle', 'id', 'created', 'query', 'qs', 'author', 'comment',  'actions'];

  ngOnInit() {
    this.dataSource = new TableDataSource();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }


  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    //   this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  constructor(public dialog: MatDialog) {}

  openDialog(action: string) {

    const dialogRef = this.dialog.open(QueryComponent, {
      width: '1000px', height: '750px', data: action
    });
  }

  openDialogCreate(action: string) {

    const dialogRef = this.dialog.open(QueryComponent, {
      width: '1000px', height: '750px', data: action
    });

  }


  deleteRow(row) {
    let result = this.dataSource.deleteRowData(row);
    this.paginator._changePageSize(this.paginator.pageSize);
    return result;
  }


  updateActiveStatus(row) {
    row.activate = !row.activate;
  }
}
