import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

// Set the http options
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'text/plain'})
};

@Injectable({
  providedIn: 'root'
})
export class AnalyzerService {

  baseURL = 'https://krata.api.krasosu.de/analyze-verbose';
  
  /*
  curl  -X POST "https://krata.api.krasosu.de/analyze-verbose"
        -H  "accept: application/json"
        -H  "Content-Type: text/plain"
        -d "sherlock holmes star trek"
   */

  constructor(private http: HttpClient) { }

  public analyze(text: string): Observable<any> {

    const body = text;

    // Call the http GET
    const res = this.http.post(this.baseURL, body,  httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );

    console.log(res);
    return res;
  }

  private extractData(res: Response) {
      let body = res;
      return body || {};
    }

  private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` + `body was: ${error.error}`
        );
      }
      // return an observable with a user-facing error message
      console.log(error);
      return throwError(error);
    }
}
