<!-- HEADER -->
<mat-card>
  <h1>awesome text anaylzer</h1>
  <h3>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
    dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
    diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
    takimata sanctus est Lorem ipsum dolor sit amet.</h3>
</mat-card>


<div fxLayout="column" fxLayoutAlign="center" style="padding: 5%">

  <app-table></app-table>


</div>
<!--

<div class="search-div">
  <button mat-raised-button>
    <mat-icon>add</mat-icon>Create
  </button>
  <mat-form-field class="search-form-field" floatLabel="never">
    <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()">
    <button mat-button matSuffix mat-icon-button aria-label="Clear"*ngIf="searchKey"  (click)="onSearchClear()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
<div class="mat-elevation-z8">
  <mat-table [dataSource]="listData"  matSort>
    <ng-container matColumnDef="fullName">
      <mat-header-cell *matHeaderCellDef  mat-sort-header>Full Name</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.fullName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef  mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="mobile">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.mobile}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="city">
      <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.city}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="departmentName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Department</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.departmentName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button><mat-icon>launch</mat-icon></button>
        <button mat-icon-button color="warn"><mat-icon>delete_outline</mat-icon></button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef colspan="6">
        Loading data...
      </mat-footer-cell>
    </ng-container>
    <ng-container matColumnDef="noData">
      <mat-footer-cell *matFooterCellDef colspan="6">
        No data.
      </mat-footer-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':listData!=null}"></mat-footer-row>
    <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(listData!=null && listData.data.length==0)}"></mat-footer-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
</div>
-->
