<mat-tab-group mat-align-tabs="center" style="height: 600px">


  <mat-tab label="Match All">

    <mat-form-field class="example-chip-list" style="padding-top: 7%">
      <mat-label>Included Terms</mat-label>
      <mat-chip-list #chipListMatchAll aria-label="MatchAll selection">
        <mat-chip (removed)="removeMatchAll(matchAll)" *ngFor="let matchAll of matchAllArray"
                  [removable]="removable" [selectable]="selectable">
          {{matchAll.name}}
          <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
        </mat-chip>
        <label>
          <input (matChipInputTokenEnd)="addMatchAll($event)"
                 [matChipInputAddOnBlur]="addOnBlur"
                 [matChipInputFor]="chipListMatchAll"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 placeholder="search term...">
        </label>
      </mat-chip-list>
    </mat-form-field>



    <h3  style="padding-top: 25%"> TODO: info box</h3>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>help</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>To perform a single character wildcard search use the "?" symbol.</p>
      <p>To perform a multiple character wildcard search use the "*" symbol.</p>
      <p>To perform a fuzzy search use the "~" symbol at the end of the term.</p>
    </mat-expansion-panel>


  </mat-tab>


  <mat-tab label="Match None">
    <mat-form-field class="example-chip-list" style="padding-top: 7%">
      <mat-label>Excluded Terms</mat-label>
      <mat-chip-list #chipListMatchNone aria-label="MatchNone selection">
        <mat-chip (removed)="removeMatchNone(matchNone)" *ngFor="let matchNone of matchNoneArray" [removable]="removable"
                  [selectable]="selectable" color="warn">
          {{matchNone.name}}
          <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
        </mat-chip>
        <label>
          <input (matChipInputTokenEnd)="addMatchNone($event)"
                 [matChipInputAddOnBlur]="addOnBlur"
                 [matChipInputFor]="chipListMatchNone"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 placeholder="search term...">
        </label>
      </mat-chip-list>
    </mat-form-field>


  </mat-tab>

  <mat-tab label="Match Any">
    <mat-form-field class="example-chip-list" style="padding-top: 7%">
      <mat-label>With at least one</mat-label>
      <mat-chip-list #chipList aria-label="MatchAny selection">
        <mat-chip (removed)="removeMatchAny(matchAny)" *ngFor="let matchAny of matchAnyArray"
                  [removable]="removable" [selectable]="selectable">
          {{matchAny.name}}
          <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input (matChipInputTokenEnd)="addMatchAny($event)"
               [matChipInputAddOnBlur]="addOnBlur"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               placeholder="search term...">
      </mat-chip-list>
    </mat-form-field>
  </mat-tab>

  <mat-tab label="Proximity">

    <form class="example-form" style="padding-top: 7%">

      <table class="example-full-width" width="100%">
        <tr>

          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Term</mat-label>
              <input matInput>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Term</mat-label>
              <input matInput>
            </mat-form-field>
          </td>

          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Proximity</mat-label>
              <input #postalCode matInput>
            </mat-form-field>
          </td>

          <td>
            <button color="primary" mat-raised-button>Submit</button>
          </td>
        </tr>
      </table>
    </form>

    <mat-form-field class="example-chip-list" style="padding-top: 7%">
      <mat-label></mat-label>
      <mat-chip-list #chipList aria-label="MatchProximity selection">
        <mat-chip (removed)="removeMatchAll(matchProximity)" *ngFor="let matchProximity of matchProximityArray"
                  [removable]="removable" [selectable]="selectable">
          {{matchProximity.name}}
          <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>

  </mat-tab>

  <mat-tab label="Advanced">


    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="padding-top: 7%">
      <mat-form-field class="example-full-width" style="width: 75%">
        <mat-label>Regular Expression</mat-label>
        <input matInput>
      </mat-form-field>
      <div>
        <button color="primary" mat-raised-button>Submit</button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="padding-top: 4%">
      <mat-form-field class="example-full-width" style="width: 75%">
        <mat-label>Lucene Query Syntax</mat-label>
        <input matInput>
      </mat-form-field>
      <div>
        <button color="primary" mat-raised-button>Submit</button>
      </div>
    </div>



    <mat-form-field class="example-chip-list" style="padding-top: 7%">
      <mat-label></mat-label>
      <mat-chip-list #chipList aria-label="MatchProximity selection">
        <mat-chip (removed)="removeRegex(regex)" *ngFor="let regex of regexArray"
                  [removable]="removable" [selectable]="selectable">
          {{regex.name}}
          <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>





  </mat-tab>


</mat-tab-group>


<!--
<mat-vertical-stepper>

  <mat-step>
    <form>
      <ng-template matStepLabel>Single Terms</ng-template>


      <mat-form-field class="example-chip-list">
        <mat-label></mat-label>
        <mat-chip-list #chipList aria-label="Fruit selection">
          <mat-chip (removed)="remove(fruit)" *ngFor="let fruit of fruits"
                    [removable]="removable" [selectable]="selectable">
            {{fruit.name}}
            <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input (matChipInputTokenEnd)="add($event)"
                 [matChipInputAddOnBlur]="addOnBlur"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 placeholder="New fruit...">
        </mat-chip-list>
      </mat-form-field>


    </form>
  </mat-step>


  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Wildcard Search</ng-template>
      <mat-form-field>
        <mat-label>Address</mat-label>
        <input formControlName="secondCtrl" matInput placeholder="Ex. 1 Main St, New York, NY"
               required>
      </mat-form-field>

    </form>
  </mat-step>


  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Fuzzy Search</ng-template>
      <mat-form-field class="example-chip-list">
        <mat-label></mat-label>
        <mat-chip-list #chipList aria-label="Fruit selection">
          <mat-chip (removed)="remove(fruit)" *ngFor="let fruit of fruits"
                    [removable]="removable" [selectable]="selectable">
            {{fruit.name}}
            <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input (matChipInputTokenEnd)="add($event)"
                 [matChipInputAddOnBlur]="addOnBlur"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 placeholder="New fruit...">
        </mat-chip-list>
      </mat-form-field>
    </form>
  </mat-step>

      <mat-step [stepControl]="fourthFormGroup">
        <form [formGroup]="fourthFormGroup">
          <ng-template matStepLabel>Distance Search</ng-template>

          <mat-form-field>
            <mat-label>Phrase</mat-label>
            <input formControlName="secondCtrl" matInput>
          </mat-form-field>

        </form>
  </mat-step>


</mat-vertical-stepper>
-->
