import {Component, Input, OnInit} from '@angular/core';
import {AnalyzerService} from '../../service/analyzer.service';
import { map, filter, switchMap } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-analyzer',
  templateUrl: './analyzer.component.html',
  styleUrls: ['./analyzer.component.scss']
})
export class AnalyzerComponent implements OnInit {

  loading = false;
  errorMessage;

  response;
  isSimplePerspective = true;



  inputDefaultText = `
  <h1>Type to analyze</h1>
  <h3>
  Example:
  The quick brown fox jumps over the lazy dog</h3>`;
  default = 'The quick brown fox jumps over the lazy dog';

  resultDefaultText = `
  <h1><br></h1>
  <h3>
  Example:
  The quick brown <b><u>fox</u></b> jumps over the lazy <b><u>dog</u></b><br><br></h3>
  <br><br><br><br><br><br><br><br><br><br><br><br><br>
  `;


  inputText = '';
  result = '';
  simpleResult = '';

  constructor(private httpClient: HttpClient, private analyzerService: AnalyzerService) {
  }

  ngOnInit(): void {
  }

  getResult() {
    
    if(this.isSimplePerspective) {
      return this.simpleResult;
    } else {
      return this.result;
    }
  }

  post(text){   
    this.analyzerService.analyze(text).subscribe(
      data => {this.result=JSON.stringify(data,undefined,2);
               this.simpleResult= data.highlight;
               console.log(this.result);
              },
      err => {console.log(err);}
    );
  }

  togglePerspective() {
    this.isSimplePerspective = !this.isSimplePerspective;
    console.log(this.isSimplePerspective)
  }

}


