<mat-nav-list fxFill fxLayout="column">
  <div class="logo-card">
    <img
      alt="Krata"
      src="assets/website_logo_transparent_background.png">
  </div>

  <mat-divider></mat-divider>

  <a mat-list-item routerLink="/" routerLinkActive="list-item-active">
    <mat-icon>find_in_page</mat-icon>
    Analyze</a>
  <a mat-list-item routerLink="/filter" routerLinkActive="list-item-active">
    <mat-icon>filter_alt</mat-icon>
    Filter</a>
  <a mat-list-item routerLink="/stuff" routerLinkActive="list-item-active">
    <mat-icon>insert_chart</mat-icon>
    Statistics</a>

  <mat-divider></mat-divider>

  <h3 matSubheader>API</h3>

  <a mat-list-item routerLink="/swagger" routerLinkActive="list-item-active">
    <mat-icon>import_contacts</mat-icon>
    Swagger
  </a>

  <span fxFlex></span>

  <div fxLayoutAlign="center center">
    <a class="mat-subheading-2" style="opacity: 50%;">Build 0.0.2-alpha</a>
  </div>
  <mat-divider></mat-divider>

</mat-nav-list>


