import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  displayedColumns: any;
  listData: null;

  constructor() {
  }

  ngOnInit(): void {
  }

}
