<app-header (toggleSideBarForMe)="sideBarToggler()"></app-header>

<mat-drawer-container>

  <mat-drawer [opened]="sideBarOpen" mode="side">
    <app-sidebar></app-sidebar>
  </mat-drawer>

  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>

</mat-drawer-container>

<app-footer></app-footer>
