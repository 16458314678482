import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {TooltipPosition} from '@angular/material/tooltip';



export interface MatchAll {
  name: string;
}

export interface MatchNone {
  name: string;
}

export interface MatchAny {
  name: string;
}

export interface MatchProximity {
  name: string;
}



@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class QueryBuilderComponent implements OnInit {


  constructor(private _formBuilder: FormBuilder) {}


  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);
  tooltip = '? = single character wildcard * &#13;\n * = multiple character wildcard';


  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;



  // -----
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];


  matchAllArray: MatchAll[] = [
    {name: 'Dog'},
    {name: 'Fo?'}
  ];

  matchNoneArray: MatchNone[] = [];

  matchAnyArray: MatchAny[] = [];

  matchProximityArray: MatchProximity[] = [];


  regexArray: MatchAll[] = [
    {name: '^(?=(?!(.)\\1)([^\\DO:105-93+30])(?-1)(?<!\\d(?<=(?![5-90-3])\\d))).[^\\WHY?]$'}
  ];


  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }


  /* Match All - Methods   */
  addMatchAll(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.matchAllArray.push({name: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  removeMatchAll(matchAll: MatchAll): void {
    const index = this.matchAllArray.indexOf(matchAll);

    if (index >= 0) {
      this.matchAllArray.splice(index, 1);
    }
  }
  /* */


  /* Match None - Methods   */
  addMatchNone(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.matchNoneArray.push({name: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  removeMatchNone(matchNone: MatchNone): void {
    const index = this.matchNoneArray.indexOf(matchNone);

    if (index >= 0) {
      this.matchNoneArray.splice(index, 1);
    }
  }
  /* */

  /* Match Any - Methods   */
  addMatchAny(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.matchAnyArray.push({name: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  removeMatchAny(matchAny: MatchAny): void {
    const index = this.matchAnyArray.indexOf(matchAny);

    if (index >= 0) {
      this.matchAnyArray.splice(index, 1);
    }
  }
  /* */



  /* Match Promity - Methods   */
  addMatchProximity(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.matchProximityArray.push({name: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  removeMatchProximity(matchProximity: MatchProximity): void {
    const index = this.matchProximityArray.indexOf(matchProximity);

    if (index >= 0) {
      this.matchProximityArray.splice(index, 1);
    }
  }
  /* */

  removeRegex(regex: MatchAll) {

  }
}
