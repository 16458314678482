


<div fxLayout="row" fxLayoutAlign="space-between start">

  <button mat-raised-button color="primary" (click)="openDialogCreate('create')" >
    <mat-icon>add</mat-icon>Create
  </button>

  <mat-form-field class="search-form-field" floatLabel="never">
    <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()">
    <button mat-button matSuffix mat-icon-button aria-label="Clear"*ngIf="searchKey"  (click)="onSearchClear()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>



<div class="mat-elevation-z8 data-table">
  <table aria-label="Elements" class="full-width-table" mat-table matSort>

    <ng-container matColumnDef="toggle">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" >
        <mat-slide-toggle [checked]="row.activate" (change)="updateActiveStatus(row)"></mat-slide-toggle>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th *matHeaderCellDef mat-header-cell mat-sort-header width="5%">Id</th>
      <td *matCellDef="let row" mat-cell>{{row.id}}</td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="created">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Created</th>
      <td *matCellDef="let row" mat-cell>{{row.created}}</td>
    </ng-container>

    <!-- Query Column -->
    <ng-container matColumnDef="query">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Query</th>
      <td *matCellDef="let row" mat-cell>{{row.query}}</td>
    </ng-container>

    <!-- QS Column -->
    <ng-container matColumnDef="qs">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>QS</th>
      <td *matCellDef="let row" mat-cell>{{row.qs}}</td>
    </ng-container>

    <!-- Author Column -->
    <ng-container matColumnDef="author">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Author</th>
      <td *matCellDef="let row" mat-cell>{{row.author}}</td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Comment</th>
      <td *matCellDef="let row" mat-cell>{{row.comment}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell width="15%"></th>
      <td *matCellDef="let row" mat-cell>
        <button mat-icon-button  #tooltip="matTooltip" matTooltip="Edit"  (click)="openDialog('update')"><mat-icon>launch</mat-icon></button>
        <button mat-icon-button #tooltip="matTooltip" matTooltip="Delete" color="warn" (click)="deleteRow(row)"><mat-icon>delete_outline</mat-icon></button>
      </td>
    </ng-container>



    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>


  </table>

  <mat-paginator #paginator
                 [length]="dataSource?.data.length"
                 [pageIndex]="0"
                 [pageSizeOptions]="[10, 25, 50, 100, 250]"
                 [pageSize]="10"  >
  </mat-paginator>
</div>
