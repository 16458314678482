import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent {

  action: string;


  constructor(
    public dialogRef: MatDialogRef<QueryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: string) {

  this.action = data;
  }


}
