<div class="container">

  <!-- HEADER -->
  <mat-card>
    <h1>awesome text anaylzer</h1>
    <h3>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
      dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
      diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
      takimata sanctus est Lorem ipsum dolor sit amet.</h3>
  </mat-card>


  <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5%" style="padding: 5%">

    <!-- LEFT -->
    <mat-card fxFlex="45%">
      <mat-card-content>

        <mat-form-field [floatLabel]="'never'">
          <textarea [(ngModel)]="inputText" matInput rows="35" style="resize: none"></textarea>
          <mat-placeholder [innerHTML]="inputDefaultText"></mat-placeholder>
        </mat-form-field>

        <mat-card-actions fxLayoutAlign="center end" fxLayoutGap="1%" style="padding-bottom: 1%">
          <button (click)="post(inputText)" color="primary" mat-raised-button>Analyze</button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>

    <!-- RIGHT -->
    <mat-card fxFlex="45%" >
      <mat-card-content>
        <mat-placeholder *ngIf="!inputText" style="height: 572px; overflow-y: auto;" [innerHTML]="resultDefaultText"></mat-placeholder>
        <pre *ngIf="inputText" style="height: 572px; padding-top: 2%; overflow-y: auto;" [innerHtml]=getResult()></pre>
        <hr>

        <!--
        <mat-form-field [floatLabel]="'never'">     
          <textarea matInput readonly rows="35" style="resize: none" > {{ getResult() }} </textarea> 
          <mat-placeholder *ngIf="inputText === ''" [innerHTML]="resultDefaultText"></mat-placeholder>
        </mat-form-field>
        -->
        
        <mat-card-actions fxLayoutAlign="end center" fxLayoutGap="1%">

          <mat-slide-toggle #tooltip="matTooltip" matTooltip="Switch perspective" (click)="togglePerspective()" ></mat-slide-toggle>
          
          <button mat-icon-button #tooltip="matTooltip" matTooltip="Copy to clipboard" [cdkCopyToClipboard]="result">
            <mat-icon>content_copy</mat-icon>
          </button>
          <button mat-icon-button #tooltip="matTooltip" matTooltip="Download">
            <mat-icon>get_app</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>

  </div>
</div>

